import "./layout.style.scss"

interface LayoutProps {
  children: React.ReactElement
  header?: React.ReactElement | null
  footer?: React.ReactElement | null
}

export const Layout = ({ children, header = null, footer = null }: LayoutProps) => {
  return (
    <div className="layout-wrapper">
      {header}
      {children}
      {footer}
    </div>
  )
}
