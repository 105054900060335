import React from "react"

import { convertIntToEuro } from "../utils/calculator-utils"

interface CustomRangeSliderProps {
  onRangeChange: (event: React.SyntheticEvent) => void
  amount: number
  rate: number
  maxDuration: number
  ratesRange: number
}

export const CustomRangeSlider = ({ onRangeChange, amount, rate, maxDuration, ratesRange }: CustomRangeSliderProps) => {
  return (
    <div>
      <div className="custom-range-slider-amount">
        <div>Zu zahlen</div>
        <div>{convertIntToEuro(amount)}</div>
      </div>
      <div className="custom-range-slider-range">
        <div>Ratenanzahl</div>
        <div>{ratesRange}</div>
      </div>
      <div>
        <input onChange={onRangeChange} min="1" max={maxDuration} type="range" value={ratesRange} className="custom-range-slider" />
      </div>
      <div className="custom-range-slider-rates">
        <div>Ratenhöhe</div>
        <div>{convertIntToEuro(rate)}</div>
      </div>
    </div>
  )
}
