import { BrowserRouter, Link, Route, Routes } from "react-router-dom"

import { SaRateCalculator } from "./modules/sa-rate-calculator/sa-rate-calculator"

export default function AppRoutes() {
  return (
    <>
      <BrowserRouter>
        <>
          <Routes>
            <Route path="/" element={<SaRateCalculator />} />
            <Route
              path="*"
              element={
                <main>
                  <p>Theres nothing here!</p>
                  <Link to="/home">Home</Link>
                </main>
              }
            />
          </Routes>
        </>
      </BrowserRouter>
    </>
  )
}
