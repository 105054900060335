import React, { useState } from "react"
import Modal from "react-modal"

import closeIcon from "../statics/images/close-icon.svg"
import { Imprint } from "./imprint"
import { Imprint as ImprintRuff } from "./imprint-ruff"
import { PrivacyPolicy } from "./privacy-policy"
import { PrivacyPolicy as PrivacyPolicyRuff } from "./privacy-policy-ruff"

export const Footer = () => {
  const [showImpressumModal, setShowImpressumModal] = useState<boolean>(false)
  const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false)

  const onImprintModalOpen = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    setShowImpressumModal(true)
  }

  const onPrivacyModalOpen = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    setShowPrivacyModal(true)
  }

  // TODO: FUN-251 remove logic later. After company merge
  const isBuyerRightmart = (): boolean => {
    const url = new URLSearchParams(document.location.search)
    const buyer = url.get("buyer")
    return buyer == "rightmart"
  }

  const currentYear = new Date().getFullYear()

  return (
    <footer className="sa-rate-calculator--footer">
      <div className="footer-copyright">© {currentYear} www.schuldenanalyse-kostenlos.de </div>
      <div className="footer-links">
        <span data-test="privacy">
          <a onClick={onPrivacyModalOpen} href="#">
            Datenschutz
          </a>
        </span>
        <span data-test="imprint">
          <a onClick={onImprintModalOpen} href="#">
            Impressum
          </a>
        </span>
      </div>

      {(showImpressumModal || showPrivacyModal) && (
        <div
          onClick={() => {
            setShowPrivacyModal(false)
            setShowImpressumModal(false)
          }}
          className="sa-rate-calculator-modal-close-icon"
        >
          <img src={closeIcon} width={25} />
        </div>
      )}

      <Modal isOpen={showImpressumModal} contentLabel="Impressum">
        {isBuyerRightmart() ? <Imprint /> : <ImprintRuff />}
      </Modal>
      <Modal isOpen={showPrivacyModal} contentLabel="Datenschutzerklärung">
        {isBuyerRightmart() ? <PrivacyPolicy /> : <PrivacyPolicyRuff />}
      </Modal>
    </footer>
  )
}
