import logo from "../statics/images/logo_mobile_raw.svg"

export const Header = () => {
  return (
    <div className="sa-rate-calculator--header">
      <div className="sa-rate-calculator--header-logo">
        <img src={logo} title="logo" width={280} />
      </div>
      {/* <div className="sa-rate-calculator--header-leftside">
        <a href="tel:030220662388">
          <span className="sa-rate-calculator--header-leftside-phone-icon"></span>
          <span className="sa-rate-calculator--header-leftside-phone-number">030 / 22 066 23 88</span>
        </a>
        <div className="sa-rate-calculator--header-leftside-hints">
          <span className="check-icon"></span>
          <span>Mo-Fr von 8-20 Uhr</span>
        </div>
      </div> */}
    </div>
  )
}
