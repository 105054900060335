import "./sa-rate-calculator.style.scss"

import axios from "axios"
import React, { useEffect, useState } from "react"

import { Layout } from "../../components/layouts/layout"
import { getUrlParameter } from "../../utils/get-url-parameter"
import { CustomRangeSlider } from "./components/custom-range-slider"
import { Footer } from "./components/footer"
import { Header } from "./components/header"
import { addAdditionalLayoutChanges } from "./utils/add-additional-layout-changes"
import { convertIntToEuro } from "./utils/calculator-utils"

const getApiUrl = () => {
  return process.env.REACT_APP_SA_API_URL || ""
}

const getYearsAndMonthsFromMonthsCount = (months: number) => {
  const years = parseInt((months / 12).toString())
  const restMonths = months - years * 12
  return { years, months: restMonths }
}

export const SaRateCalculator = () => {
  const [ratesRange, setRatesRange] = useState<number>()
  const [amount, setAmount] = useState<number>()
  const [rate, setRate] = useState<number>()
  const [maxDuration, setMaxDuration] = useState<number>()
  const [showResult, setShowResult] = useState<boolean>()

  const debtSum = parseFloat(getUrlParameter("debtSum"))
  const durationMax = isFinite(parseFloat(getUrlParameter("durationMax"))) ? parseFloat(getUrlParameter("durationMax")) : 120
  const durationDenied =
    parseFloat(getUrlParameter("durationDenied")) < durationMax ? parseFloat(getUrlParameter("durationDenied")) : durationMax
  const payOffQuotaNew = parseFloat(getUrlParameter("payOffQuotaNew"))
  const rId = getUrlParameter("ID")
  const token = getUrlParameter("token")

  useEffect(() => {
    addAdditionalLayoutChanges()
  }, [])

  useEffect(() => {
    setRatesRange(durationDenied)
  }, [durationDenied])

  useEffect(() => {
    setAmount(debtSum * payOffQuotaNew)
  }, [debtSum, payOffQuotaNew])

  useEffect(() => {
    if (!durationMax) {
      return
    }
    setMaxDuration(durationMax)
  }, [durationMax])

  useEffect(() => {
    if (!amount || !ratesRange) {
      return
    }
    setRate(amount / ratesRange)
  }, [amount, ratesRange])

  const onRangeChange = (event: React.BaseSyntheticEvent) => {
    const rates = event.target.value
    setRatesRange(rates)
  }

  const handleButtonClick = async () => {
    if (!amount || !rate) {
      return
    }

    const data = {
      amount: convertIntToEuro(amount),
      rate: convertIntToEuro(rate),
      ratesRange,
      maxDuration,
      token,
      rId
    }
    try {
      await axios.post(getApiUrl(), data)
    } catch {
      // was soll beim error passieren ?
    }

    setShowResult(true)
  }

  const renderSummary = () => {
    const { months, years } = getYearsAndMonthsFromMonthsCount(ratesRange || 0)
    let termText = ""
    let termTextMonths = "Monate"
    if (years === 0 && months === 1) {
      termText = ``
      termTextMonths = `Monat`
    } else if (years === 1 && months === 1) {
      termText = ` Das entspricht <em>${years}</em> Jahr und <em>${months}</em/> Monat.`
    } else if (years === 1 && months === 0) {
      termText = ` Das entspricht <em>${years}</em> Jahr.`
    } else if (years > 1 && months === 0) {
      termText = ` Das entspricht <em>${years}</em> Jahren.`
    } else if (years > 1 && months === 1) {
      termText = ` Das entspricht <em>${years}</em> Jahren und <em>${months}</em> Monat.`
    } else if (years === 0 && months > 1) {
      termText = ``
    } else if (years === 1 && months > 0) {
      termText = ` Das entspricht <em>${years}</em> Jahr und <em>${months}</em> Monaten.`
    } else {
      termText = ` Das entspricht <em>${years}</em> Jahren und <em>${months}</em> Monaten.`
    }

    return (
      <div className="lp-calculator-summary">
        <h3>Zusammenfassung</h3>
        <p>
          Sie möchten dem Gläubiger anbieten, <em>{convertIntToEuro(rate)}</em> über <em>{ratesRange}</em>{" "}
          <span dangerouslySetInnerHTML={{ __html: termTextMonths }} /> zu zahlen.
          <span dangerouslySetInnerHTML={{ __html: termText }} />
        </p>
      </div>
    )
  }

  if (!debtSum || !durationDenied || !payOffQuotaNew || !rId || !token) {
    return (
      <Layout header={<Header />} footer={<Footer />}>
        <div className="sa-rate-calculator">
          <div className="lp-calculator-wrapper">
            <h1>Angebotsfreigabe</h1>
            <div className="lp-calculator-free-text">
              <p>Die Nutzung dieser Seite ist nur über persönliche Links möglich.</p>
              <p>Sie finden Ihren individuellen Link in der E-Mail, die Sie von uns diesbezüglich erhalten haben.</p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout header={<Header />} footer={<Footer />}>
      <div className="sa-rate-calculator">
        {showResult ? (
          <div className="lp-calculator-thank">
            <h2>Vielen Dank</h2>
            <p>Wir haben Ihr Angebot erhalten und werden dieses umgehend Ihrem Gläubiger weiterleiten.</p>
            <p>Sobald neue Erkenntnisse vorliegen, setzen wir uns mit Ihnen in Verbindung.</p>
          </div>
        ) : (
          <>
            <div className="lp-calculator-wrapper">
              <h1>Angebotsfreigabe</h1>
              <div className="lp-calculator-free-text">
                <p>
                  Mit Klick auf “Angebot freigeben” lassen Sie uns das entsprechende neue Angebot verbindlich zukommen und wir unterbreiten
                  dieses umgehend Ihrem Gläubiger. Sobald uns dazu eine Antwort eingeht, kommen wir erneut auf Sie zu.
                </p>
                <p>
                  Um den Ratenplan auf den für Sie passenden monatlichen Betrag anzupassen, können Sie den unteren “Ratenanzahl”-Regler
                  verschieben.
                </p>
              </div>
              <CustomRangeSlider
                onRangeChange={onRangeChange}
                amount={amount || 0}
                rate={rate || 0}
                maxDuration={maxDuration || 0}
                ratesRange={ratesRange || 0}
              />
            </div>
            {renderSummary()}
            <button onClick={handleButtonClick} className="lp-calculator-button">
              Angebot freigeben
            </button>
          </>
        )}
      </div>
    </Layout>
  )
}
