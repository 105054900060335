import { getUrlParameter } from "../../../utils/get-url-parameter"

export const isCalculatorLangingPageEnabled = () => {
  const debtSum = getUrlParameter("debtSum")
  const durationDenied = getUrlParameter("durationDenied")
  const payOffQuotaNew = getUrlParameter("payOffQuotaNew")

  return debtSum && durationDenied && payOffQuotaNew
}

export const convertIntToEuro = (value: string | number | undefined) => {
  if (!value) {
    return value
  }

  // @ts-ignore: Unreachable code error
  return value.toLocaleString("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2
  })
}
