export const addAdditionalLayoutChanges = () => {
  document.title = "Schuldencheck - präsentiert von schuldenanalyse-kostenlos.de"
  document.body.style.background = "#F3F3F2"
  document.body.style.overflowX = "hidden"

  addEventListener("load", () => {
    const layoutWrapper: HTMLBaseElement | null = document.querySelector("body .layout-wrapper")
    if (layoutWrapper) {
      layoutWrapper.style.overflowX = "hidden"
    }
  })
}
